<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-23.mp3"),
      },
      timeStampList: [5, 14.1, 18, 22.5, 27, 31, 35.4, 39],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "d,t,n,l",
          },
          {
            id: 2,
            chineseWords: "d-à-dà,dà,dà,dà,dà（大）",
          },
          {
            id: 3,
            chineseWords: "t-ī-tī,tī,tī,tī,tī（踢）",
          },
          {
            id: 4,
            chineseWords: "d-ī-dī,dī,dī,dī,dī（滴）",
          },
          {
            id: 5,
            chineseWords: "l-ù-lù,lù,lù,lù,lù（路）",
          },
          {
            id: 6,
            chineseWords: "d-u-ō-duō,duō,duō,duō,duō（多）",
          },
          {
            id: 7,
            chineseWords: "lālālā…（啦啦啦……）",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
